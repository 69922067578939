import React from 'react';

import Page from 'containers/page';
import WebShop from 'containers/WebShop';

export default () => {
  return (
    <Page activeItemId="home" seoProps={{
      title: "Easyresto Webshop",
    }}>
         <WebShop/>
    </Page>
  );
};