import * as React from 'react';

import Banner from './Banner';
import Features from './Features';
import TrackAudience from './TrackAudience';
import Testimonial from './Testimonial';

export default () => {
  return <>
    <Banner/>
    <TrackAudience/>
    <Features/>
    <Testimonial/>
  </>
}