import React from 'react';
import Container from 'common/components/UI/Container';
import BlogPost from 'common/components/BlogPost';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import { Section, SectionHeading, FeatureWrapper } from './features.style';

import responsiveImage from 'common/assets/image/responsive.png';
import androidAndIosImage from 'common/assets/image/androidAndIos.png';
import paymentsImage from 'common/assets/image/payments.png';
import customersImage from 'common/assets/image/customers.png';

const Features = () => {
  const features = [
    {id: 'responsive', url: responsiveImage, title: 'Optimiert für Smartphones', description: 'Über 90% aller' +
        ' Bestellungen werden am Smartphone getätigt. Wir bieten dir eine für alle gängigen Smartphones optimierte und reaktionsschnelle Webseite.'},
    {id: 'andriodAndIos', url: androidAndIosImage, title: 'Android & iOS app', description: 'Hole dir eine native' +
        ' Android-' +
        ' und iOS-App für dein Unternehmen. Beeindrucke deine Kunden mit unseren intuitiven und Bestell-Apps für eine optimale Kundenerfahrung.'},
    {id: 'payments', url: paymentsImage, title: 'Einfache Integration von Online Zahlungen', description: 'Integriere' +
        ' ganz einfach Online-Zahlungsmethoden wie PayPal, Klarna, Kreditkarten, Giropay und viele mehr.'},
    {id: 'customers', url: customersImage, title: 'Dein Kundenportal', description: 'Verwalte deinen Webhop ganz' +
        ' einfach über deinen Computer oder dein Smartphone. Ändere das Menü, dein Liefergebiet, erstelle Rabatte, Gutscheine und vieles mehr.'},
  ];
  return (
    <Section id="features">
      <Container noGutter>
        <FeatureWrapper>
          {features?.map((feature) => (
            <BlogPost
              className="feature-item"
              key={feature.id}
              thumbUrl={feature.url}
              title={feature.title}
              excerpt={feature.description}
            />
          ))}
        </FeatureWrapper>
      </Container>
    </Section>
  );
};

export default Features;
