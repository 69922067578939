import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import {Button} from 'baseui/button';
import Text from 'common/components/Text';
import GatsbyImage from 'common/components/GatsbyImage';
import {
  Section,
  ContentWrapper,
  TextContent,
  Illustration,
  MobileIllustration,
  ButtonGroup,
} from './banner.style';
import {Link} from 'gatsby';

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/saasMinimal2/banner/order-system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 974
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <Section id="home">
      <Container>
        <ContentWrapper>
          <TextContent>
            <Heading content="Kundenfreundliche Websites und Apps, die deine Conversion steigern."/>
            <MobileIllustration>
              <GatsbyImage
                src={
                  (data.illustration !== null) | undefined
                    ? data.illustration.childImageSharp.gatsbyImageData
                    : {}
                }
                alt="Illustration"
              />
            </MobileIllustration>
            <Text
              content="Easyresto macht es deinen Kunden so einfach wie möglich, Essen zu bestellen. Du erhältst eigene optimierte Webseite und App, um Bestellungen direkt über deine Vertriebskanäle  entgegenzunehmen. Deine Kunden können über jedes Gerät direkt bei dir bestellen. Das bedeutet mehr Wachstum für deinen Gastronomiebetrieb. Ganz easy."/>
            <ButtonGroup>
              <Link to="/contact-us/?web=1&app=1">
                <Button kind="primary">KOSTENFREIE DEMO</Button>
              </Link>
            </ButtonGroup>
          </TextContent>
        </ContentWrapper>
      </Container>
      <Illustration>
        <GatsbyImage
          src={
            (data.illustration !== null) | undefined
              ? data.illustration.childImageSharp.gatsbyImageData
              : {}
          }
          alt="Illustration"
        />
      </Illustration>
    </Section>
  );
};

export default Banner;
