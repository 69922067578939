import React from 'react';
import { Icon } from 'react-icons-kit';
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Link from 'common/components/Link';
import Text from 'common/components/Text';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from 'common/components/GatsbyImage';

import {
  Section,
  ContentWrapper,
  Illustration,
  TextContent,
} from './trackAudience.style';

const TrackAudience = () => {
  const data = useStaticQuery(graphql`
    query {
      pos: file(
        relativePath: { eq: "image/appshop.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      shop: file(
        relativePath: { eq: "image/webshop.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      },
      mobile: file(
        relativePath: { eq: "image/dashboard.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <Section>
      <Container>
        <ContentWrapper>
          <Illustration>
            <GatsbyImage
              src={
                (data.shop !== null) | undefined
                  ? data.shop.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Illustration"
            />
          </Illustration>
          <TextContent>
            <Heading
              className="title"
              content="Webshop"
            />
            <Text
              className="desc"
              content="Wir erstellen dir deinen eigenen optimierten Webshop. Deine eigene Webseite ist das perfekte Aushängeschild für deinen Lieferdienst. Dein Webshop wird auf allen Geräten optimal angezeigt. Egal ob Desktop-Version oder als mobile Webseite. Verwandle deine Kunden von Portalen in Stammkunden auf deiner Webseite und optimiere dein Einkommen."
            />
          </TextContent>
        </ContentWrapper>
        <ContentWrapper>
          <Illustration>
            <GatsbyImage
              src={
                (data.mobile !== null) | undefined
                  ? data.mobile.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Illustration"
            />
          </Illustration>
          <TextContent>
            <Heading
              className="title"
              content="Shopmanager"
            />
            <Text
              className="desc"
              content="Im Dashboard kannst du selbst deine Speisekarte, Rabattaktionen, Postleitzahlgebiete, Bestellungen, Rechnungen sowie Öffnungszeiten in echtzeit verwalten. Detaillierte Statistiken zeigen die Performance deines Geschäftes. Das erleichtert dir den Alltag enorm. "
            />
          </TextContent>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default TrackAudience;
